import React from 'react';
import CoreGuard from '@guards->Core.guard';
import Error, { type ErrorProps } from '@ui->Error/Error';

function ErrorGuard({
  children, heading = null, message = null, screen = false,
}: Props) {
  return (
    <CoreGuard
      allowed={!heading && !message}
      Fallback={<Error heading={heading} message={message} screen={screen} />}
    >
      {children}
    </CoreGuard>
  );
}

type Props = WithAllChildren<ErrorProps>;

export default ErrorGuard;
