import React from 'react';

function CoreGuard({ allowed, Fallback, children }: Props) {
  if (!allowed) {
    return Fallback || null;
  }

  return children as React.ReactElement;
}

type Props = WithAllChildren<{
  allowed: boolean
  Fallback?: React.ReactElement | undefined
}>;

export type {
  Props as CoreGuardProps,
};

export default CoreGuard;
