'use client';

import React from 'react';
import { useMeQuery } from '@generated->anywhere';
import useIsLogged from '@hooks->useIsLogged';
import ErrorGuard from '@guards->Error.guard';
import { useAppDispatch, useAppSelector } from '@providers->Redux/useReduxHooks';
import useAuthStore from '@application->useAuthStore';
import { clearAdminUser, setAdminUser } from '@application->account/admin_user.reducer';

function FetchUserGuard({ children }: ElementChildren) {
  const logged = useIsLogged();
  const token = useAuthStore();

  const dispatch = useAppDispatch();
  const id = useAppSelector((state) => state.account.id);
  const { data, error } = useMeQuery({
    skip: !token || logged,
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    if (data?.me && !logged) {
      dispatch(setAdminUser(data.me));
    }
  }, [data?.me, logged, dispatch]);

  React.useEffect(() => {
    if (!token && id) {
      dispatch(clearAdminUser());
    }
  }, [token, id, dispatch]);

  return (
    <ErrorGuard heading={error?.message ?? null}>
      {children}
    </ErrorGuard>
  );
}

export default FetchUserGuard;
