'use client';

import React from 'react';
import navigationConfig, { SideNavigationRoute } from '@config->navigation.config';
import { useSelectedLayoutSegments } from 'next/navigation';
import Logo from '@components->Logo/Logo';
import LinkChildren from '@ui->Link/LinkChildren';
import findRoute from '@helpers->findRoute';
import { AccountCircleOutlined } from '@mui/icons-material';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import NavigationSideRouteClient from './NavigationSideRouteClient';
import Logout from './Logout';

function Sidebar() {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const currentSegments = useSelectedLayoutSegments();

  if (currentSegments[0] === 'login' || currentSegments[0] === 'forgot-password' || currentSegments[0] === 'auth') {
    return null;
  }

  const profileRoute = findRoute('account');

  return (
    <div>
      {/* Mobile */}
      <Transition.Root show={sidebarOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={React.Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={React.Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <Logo variant="small" redirectHomeUrl="/cases" />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigationConfig.sideNavigation.map((sideNavigation) => (
                            <NavigationSideRouteClient
                              key={sideNavigation.id}
                              item={sideNavigation as SideNavigationRoute}
                              setSidebarOpen={setSidebarOpen}
                            />
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* desktop */}
      <div className="hidden lg:flex fixed inset-y-0 z-40 w-[240px] border-secondary-grey grow flex-col gap-y-5 overflow-y-auto border-r border-gray-4 bg-white px-6">
        <div className="flex justify-between items-center">
          <div className="flex h-16 shrink-0 items-center">
            <Logo variant="large" redirectHomeUrl="/cases" />
          </div>
          {/* <div className="">
            <img src="/assets/menu-hamburger.svg" alt="" />
          </div> */}
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {navigationConfig.sideNavigation.map((sideNavigation) => (
                  <NavigationSideRouteClient key={sideNavigation.id} item={sideNavigation as SideNavigationRoute} />
                ))}
              </ul>
            </li>
            <li className="-mx-6 mt-auto">
              <LinkChildren
                href={profileRoute.path}
                className="flex text-[14px] items-center gap-x-4 px-6 py-3 text-sm leading-6 text-secondary hover:text-primary hover:bg-grey-5 transition-all duration-300 ease-in"
              >
                <AccountCircleOutlined />
                <span className="sr-only">Your profile</span>
                <span aria-hidden="true">Your profile</span>
              </LinkChildren>
              <Logout />
            </li>
          </ul>
        </nav>
      </div>

      <div className="absolute w-full px-[15px] py-[24px] bg-white z-50 lg:hidden">
        <div className="flex justify-between">
          <Logo variant="large" redirectHomeUrl="/cases" />
          <button type="button" className="text-secondary lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
