'use client';

import React from 'react';
import type { SideNavigationRoute } from '@config->navigation.config';
import { useSelectedLayoutSegments } from 'next/navigation';
import clsx from 'clsx';
import LinkChildren from '@ui->Link/LinkChildren';
import { useChatUnreadMessagesProvider } from '@components->Chat/ChatSession';

function NavigationSideRouteClient(
  {
    item, setSidebarOpen,
  }: {
    item: SideNavigationRoute,
    // eslint-disable-next-line react/require-default-props
    setSidebarOpen?: React.Dispatch<React.SetStateAction<boolean>>
  },
) {
  const currentSegments = useSelectedLayoutSegments();
  const unreadMessagesData = useChatUnreadMessagesProvider();

  if (!item.showInMenu) {
    return null;
  }

  return (
    <li key={item.id}>
      <LinkChildren
        href={item.path}
        onClick={() => setSidebarOpen && setSidebarOpen(false)}
        className={clsx(
          item.id === currentSegments[0] || item.id === currentSegments[1]
            ? 'bg-grey-5 !text-primary'
            : 'text-secondary hover:text-primary hover:bg-grey-5 transition-all duration-300 ease-in',
          'group flex gap-x-3 items-center rounded-[8px] text-[14px]',
        )}
      >
        <item.icon
          className={clsx(
            item.id === currentSegments[0] || item.id === currentSegments[1] ? 'text-primary' : 'text-secondary-600 group-hover:text-primary transition-all duration-300 ease-in',
            'h-6 w-6 shrink-0',
          )}
          aria-hidden="true"
        />
        {item.label}
        {item.id === 'conversations' && unreadMessagesData.unreadMessageCount > 0 && (
          <span
            className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
            aria-hidden="true"
          >
            { unreadMessagesData.unreadMessageCount || 0 }
          </span>
        )}
      </LinkChildren>
    </li>
  );
}

export default React.memo(NavigationSideRouteClient);
