import { AdminUserDetailFragment } from '@generated->anywhere';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

const adminUserSlice = createSlice({
  name: 'AdminUser',
  initialState: {
    id: null as Nullable<string>,
    name: null as Nullable<string>,
    email: null as Nullable<string>,
    description: null as Nullable<string>,
    enabled: null as Nullable<boolean>,
  },
  reducers: {
    setUser: (_, action: PayloadAction<AdminUserDetailFragment>) => ({
      id: action.payload.id,
      name: action.payload.name ?? null,
      email: action.payload.email ?? null,
      description: action.payload.description ?? null,
      enabled: action.payload.enabled ?? null,
    }),
    clearUser: () => ({
      id: null,
      name: null,
      email: null,
      description: null,
      enabled: null,
    }),
  },
});

export const {
  setUser: setAdminUser,
  clearUser: clearAdminUser,
} = adminUserSlice.actions;

export default adminUserSlice.reducer;
