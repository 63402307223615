import { useAuthProvider } from '@application->Auth.provider';
import React from 'react';

function useAuthStore() {
  const instance = useAuthProvider();
  const selector = React.useMemo(() => (
    instance.createParameters()
  ), [instance]);

  return React.useSyncExternalStore(...selector);
}

export default useAuthStore;
