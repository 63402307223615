'use client';

import { Provider } from 'react-redux';
import { store } from '@providers->Redux/store';

function Redux({ children }: ElementChildren) {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}

export default Redux;
