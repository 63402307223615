import React from 'react';
import getLinkStyle from '@ui->Link/getLinkStyle';
import LocalizedLink from 'next/link';
import type { LinkStyleProps } from '@ui->Link/Link.styles';

function Link({
  label, href, as, onClick = null, icon = null, ...styles
}: Props) {
  const styling = getLinkStyle(styles);

  const additional = onClick ? { onClick: () => onClick() } : {};

  return (
    <LocalizedLink href={href} as={as || href} passHref legacyBehavior>
      <a {...additional} className={styling}>
        {icon && <div className="w-[24px] flex justify-between"><img src={icon} alt="" /></div>}
        {label}
      </a>
    </LocalizedLink>
  );
}

type Props = Readonly<LinkStyleProps & {
  label: string;
  href: string;
  as?: string;
  icon?: Nullable<string>;
  onClick?: Nullable<() => void>;
}>;

export default React.memo(Link);
