'use client';

import {
  ApolloClient,
  ApolloProvider as Apollo,
  // createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import useAuthStore from '@application->useAuthStore';
import { onError } from '@apollo/client/link/error';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import useAdminUserActions from '@application->account/useAdminUser.actions';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

/*
const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_ANYWHERE_API as string,
});
*/
const httpUploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_ANYWHERE_API as string,
});

function ApolloProvider({ children, locale }: Props) {
  const accessToken = useAuthStore();
  const { refresh, push } = useRouter();
  const { logoutAdminUser } = useAdminUserActions();
  const [logoutTrigged, setLogoutTrigged] = useState<boolean>(false);

  const authLink = (
    setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Accept-Language': locale,
      },
    }))
  );

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        if (message === 'Unauthenticated.' && !logoutTrigged) {
          setLogoutTrigged(true);
        }
      });
    }
  });

  const logout = async () => {
    await axios.post('/api/logout');
    logoutAdminUser();
    await push('/login');
    await refresh();
  };

  useEffect(() => {
    if (logoutTrigged === true) {
      logout();
    }
  }, [logoutTrigged]);

  const apollo = new ApolloClient({
    link: from([authLink, errorLink, httpUploadLink]),
    cache: new InMemoryCache(),
  });

  return (
    <Apollo client={apollo}>
      {children}
    </Apollo>
  );
}

type Props = WithElementChildren<{
  locale: string,
}>;

export default ApolloProvider;
