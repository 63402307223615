import { configureStore } from '@reduxjs/toolkit';
import navigationReducer from '@application->navigation.reducer';
import accountReducer from '@application->account/admin_user.reducer';
import loginReducer from '@application->login/login.reducer';

export const store = configureStore({
  reducer: {
    navigation: navigationReducer,
    account: accountReducer,
    login: loginReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
