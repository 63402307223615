import linkStyles, { type LinkStyleProps } from '@ui->Link/Link.styles';
import clsx from 'clsx';

function getLinkStyle({ size = 'md', variant = 'primary', className }: LinkStyleProps) {
  return clsx(
    linkStyles.common,
    linkStyles.sizes[size],
    linkStyles.variants[variant],
    className,
  );
}

export default getLinkStyle;
