import LogoSmall from '@components->Logo/LogoSmall';
import LogoLarge from '@components->Logo/LogoLarge';
import LinkChildren from '@ui->Link/LinkChildren';

function Logo({ redirectHomeUrl, variant, className }: Props) {
  const Element = variant === 'small' ? LogoSmall : LogoLarge;

  if (redirectHomeUrl) {
    return (
      <LinkChildren href={redirectHomeUrl}>
        <Element className={className} />
      </LinkChildren>
    );
  }

  return <Element className={className} />;
}

type Props = Readonly<{
  redirectHomeUrl?: string;
  className?: string;
  variant: 'small' | 'large';
}>;

export default Logo;
