import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    show: false,
  },
  reducers: {
    setShow: (state, action: PayloadAction<boolean>) => ({
      ...state,
      show: action.payload,
    }),
    open: (state) => ({
      ...state,
      show: true,
    }),
    close: (state) => ({
      ...state,
      show: false,
    }),
  },
});

export const {
  setShow: setNavigationMenu,
  close: closeNavigationMenu,
  open: openNavigationMenu,
} = navigationSlice.actions;

export default navigationSlice.reducer;
