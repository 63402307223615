const linkStyles = {
  variants: {
    primary: '',
    secondary: '',
    menuSecondary: '',
    link: 'bg-transparent hover:text-primary font-400',
    none: '',
    buttonPrimary: 'bg-primary hover:bg-primary-dark text-white text-[16px] leading-[20px] px-[24px] py-[10px] rounded-md',
  },
  sizes: {
    xs: '',
    sm: '',
    md: 'text-[11px] leading-[11px] p-[6px]',
    lg: 'text-[13px] leading-[13px] p-[12px]',
    xl: 'text-[16px] leading-[20px] px-[24px] py-[12px]',
    none: '',
  },
  common: 'cursor-pointer text-secondary focus-visible:outline focus-visible:outline-0 focus-visible:outline-offset-0 transition-all duration-200 ease-in',
};

type LinkVariant = keyof typeof linkStyles.variants;

type LinkSize = keyof typeof linkStyles.sizes;

type LinkStyleProps = Readonly<{
  variant?: LinkVariant;
  size?: LinkSize;
  className?: string;
}>;

export type {
  LinkVariant,
  LinkSize,
  LinkStyleProps,
};

export default linkStyles;
