const alertStyles = {
  states: {
    success: {
      title: 'text-green-800',
      description: 'text-green-700',
      background: 'bg-green-50',
      link: 'text-green-700 underline hover:text-green-600',
      icon: 'text-green-400',
    },
    error: {
      title: 'text-red-800',
      description: 'text-red-700',
      background: 'bg-red-50',
      link: 'text-red-700 underline hover:text-red-600',
      icon: 'text-red-400',
    },
    warning: {
      title: 'text-yellow-800',
      description: 'text-yellow-700',
      background: 'bg-yellow-50',
      link: 'text-yellow-700 underline hover:text-yellow-600',
      icon: 'text-yellow-400',
    },
    info: {
      title: 'text-secondary-800',
      description: 'text-secondary-700',
      background: 'bg-secondary-50',
      link: 'text-secondary-700 underline hover:text-primary-secondary',
      icon: 'text-secondary-400',
    },
  },
  common: {
    title: 'text-sm font-medium',
    description: 'mt-2 text-sm',
    link: 'text-sm font-medium underline',
    icon: 'w-5 h-5',
    background: 'rounded-lg p-4',
  },
};

type AlertState = keyof typeof alertStyles.states;

type AlertStyles = Readonly<{
  state?: AlertState;
  className?: string
}>;

export type {
  AlertState,
  AlertStyles as AlertStyleProps,
};

export default alertStyles;
