import React from 'react';
import { useAppDispatch } from '@providers->Redux/useReduxHooks';
import { AdminUserDetailFragment } from '@generated->anywhere';
import { clearAdminUser, setAdminUser } from './admin_user.reducer';

function useAdminUserActions() {
  const dispatch = useAppDispatch();

  function setAdminUserFromFragment(fragment: AdminUserDetailFragment) {
    dispatch(setAdminUser(fragment));
  }

  function logoutAdminUser() {
    dispatch(clearAdminUser());
  }

  return {
    logoutAdminUser: React.useCallback(logoutAdminUser, [dispatch]),
    setAdminUserFromFragment: React.useCallback(setAdminUserFromFragment, [dispatch]),
  };
}

export default useAdminUserActions;
