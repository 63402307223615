import type React from 'react';

function LogoLarge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="164" height="23" viewBox="0 0 164 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_283_726)">
        <path d="M16.8991 10.4971V15.541C16.8721 16.4339 17.0781 17.3186 17.4977 18.1113H12.5121C12.2284 17.6978 12.0662 17.2155 12.0435 16.7175V16.691C11.3141 17.3426 10.4609 17.8465 9.53285 18.1739C8.60477 18.5013 7.62004 18.6457 6.63502 18.5989C3.71933 18.5989 0.00473022 17.4742 0.00473022 13.9989C0.00473022 10.5236 3.71933 9.39886 6.63502 9.39886C7.62236 9.35002 8.60963 9.49592 9.53857 9.82795C10.4675 10.16 11.3192 10.6714 12.0435 11.332V10.4523C12.0435 8.39606 10.4984 7.41742 7.48313 7.41742C5.7677 7.37 4.06603 7.73062 2.52329 8.46852L1.40106 5.41066C3.64811 4.47791 6.06496 4.0037 8.50459 4.01687C13.7561 4.01342 16.8991 6.31341 16.8991 10.4971ZM12.1384 13.8482C11.6306 13.2793 10.9943 12.8349 10.2804 12.5503C9.56649 12.2658 8.79471 12.1491 8.02666 12.2095C6.63032 12.2095 4.76073 12.5545 4.76073 13.9966C4.76073 15.4387 6.63501 15.7825 8.02666 15.7825C8.79731 15.8438 9.57177 15.7248 10.2865 15.4353C11.0012 15.1459 11.6359 14.6942 12.1384 14.1173V13.8482Z" fill="#00004B" />
        <path d="M20.5387 18.1069V4.5518H25.4481V6.70575C26.0982 5.83747 26.9577 5.14126 27.9496 4.6796C28.9414 4.21795 30.0345 4.0053 31.1307 4.06075C31.9596 3.99415 32.7933 4.10539 33.574 4.38673C34.3547 4.66808 35.0637 5.1128 35.6518 5.69005C36.24 6.2673 36.6931 6.96325 36.9798 7.72962C37.2666 8.49599 37.38 9.31443 37.3123 10.1282V18.1046H32.4017V10.4467C32.4405 10.0667 32.3919 9.68302 32.2596 9.32391C32.1272 8.96479 31.9146 8.6394 31.6371 8.37169C31.3597 8.10398 31.0246 7.90078 30.6566 7.77705C30.2886 7.65333 29.897 7.61224 29.5106 7.6568C28.6737 7.66217 27.8539 7.89023 27.1385 8.31671C26.4231 8.74319 25.839 9.35213 25.4481 10.0787V18.1046L20.5387 18.1069Z" fill="#00004B" />
        <path d="M56.9525 4.55176L47.2824 23H42.3226L45.4632 17.3478L38.6337 4.55176H43.6931L47.9103 12.9686L51.9728 4.55176H56.9525Z" fill="#00004B" />
        <path d="M83.2218 4.55176L78.3112 18.1068H73.6255L70.4849 9.10231L67.322 18.1068H62.6047L57.7186 4.55176H62.4536L65.3212 13.2124L68.3353 4.55176H72.6696L75.6848 13.2124L78.5525 4.55176H83.2218Z" fill="#00004B" />
        <path d="M102.287 10.1304V18.1068H97.3773V10.4489C97.4163 10.069 97.3679 9.68531 97.2357 9.32618C97.1035 8.96706 96.8909 8.64165 96.6136 8.37391C96.3363 8.10618 96.0013 7.90295 95.6333 7.77922C95.2653 7.65549 94.8737 7.61441 94.4874 7.659C93.6502 7.66404 92.8302 7.89195 92.1145 8.31846C91.3989 8.74497 90.8145 9.35408 90.4237 10.0809V18.1068H85.5131V0H90.4237V6.70566C91.0737 5.83731 91.9333 5.14103 92.9251 4.67937C93.917 4.2177 95.0101 4.0051 96.1063 4.06066C96.9354 3.99378 97.7694 4.10492 98.5503 4.38634C99.3313 4.66776 100.04 5.11271 100.629 5.69029C101.217 6.26786 101.67 6.9642 101.956 7.73095C102.242 8.4977 102.355 9.31646 102.287 10.1304Z" fill="#00004B" />
        <path d="M114.576 15.2446C116.342 15.3333 118.08 14.7848 119.462 13.7024L121.954 16.2715C119.802 17.988 117.069 18.8443 114.302 18.6693C108.445 18.6693 105.031 15.8794 105.031 11.3288C105.031 6.77826 108.395 3.9895 113.934 3.9895C118.919 3.9895 122.459 6.43671 122.459 11.2345C122.483 11.6141 122.441 11.9949 122.333 12.3603H109.716C110.115 14.4119 111.785 15.2446 114.576 15.2446ZM109.841 9.76251L117.916 9.7142C117.394 8.12375 116.072 7.36475 113.979 7.36475C111.86 7.36475 110.39 8.12376 109.841 9.76251Z" fill="#00004B" />
        <path d="M125.269 18.1068V4.55171H130.18V7.07136C130.801 6.13784 131.664 5.38312 132.68 4.88443C133.696 4.38574 134.828 4.161 135.962 4.23317V8.24552C133.022 8.24552 130.902 9.34607 130.18 11.5736V18.1068H125.269Z" fill="#00004B" />
        <path d="M146.754 15.2445C148.518 15.3317 150.253 14.7827 151.633 13.7012L154.126 16.2703C151.975 17.9873 149.242 18.8436 146.475 18.668C140.618 18.668 137.204 15.8781 137.204 11.3276C137.204 6.77704 140.569 3.98828 146.106 3.98828C151.091 3.98828 154.63 6.43548 154.63 11.2333C154.655 11.6128 154.613 11.9937 154.506 12.3591H141.894C142.292 14.4119 143.962 15.2445 146.754 15.2445ZM142.017 9.76244L150.1 9.71414C149.575 8.12369 148.255 7.36469 146.161 7.36469C144.038 7.36469 142.566 8.12369 142.017 9.76244Z" fill="#00004B" />
        <path d="M161.657 18.2309C162.951 18.2309 164 17.2011 164 15.9309C164 14.6606 162.951 13.6309 161.657 13.6309C160.363 13.6309 159.314 14.6606 159.314 15.9309C159.314 17.2011 160.363 18.2309 161.657 18.2309Z" fill="#FA7D00" />
      </g>
      <defs>
        <clipPath id="clip0_283_726">
          <rect width="164" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoLarge;
