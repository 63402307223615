import type React from 'react';

function LogoSmall(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.8991 10.4974V15.5413C16.8721 16.4341 17.0781 17.3189 17.4977 18.1115H12.5121C12.2284 17.698 12.0662 17.2157 12.0435 16.7177V16.6913C11.3141 17.3428 10.4609 17.8468 9.53285 18.1742C8.60477 18.5015 7.62004 18.6459 6.63502 18.5991C3.71933 18.5991 0.00473022 17.4744 0.00473022 13.9991C0.00473022 10.5238 3.71933 9.3991 6.63502 9.3991C7.62236 9.35026 8.60963 9.49617 9.53857 9.8282C10.4675 10.1602 11.3192 10.6716 12.0435 11.3323V10.4525C12.0435 8.3963 10.4984 7.41766 7.48313 7.41766C5.7677 7.37024 4.06603 7.73086 2.52329 8.46876L1.40106 5.4109C3.64811 4.47815 6.06496 4.00395 8.50459 4.01711C13.7561 4.01366 16.8991 6.31366 16.8991 10.4974ZM12.1384 13.8485C11.6306 13.2796 10.9943 12.8351 10.2804 12.5506C9.56649 12.266 8.79471 12.1493 8.02666 12.2097C6.63032 12.2097 4.76073 12.5547 4.76073 13.9968C4.76073 15.4389 6.63501 15.7828 8.02666 15.7828C8.79731 15.844 9.57177 15.725 10.2865 15.4356C11.0012 15.1461 11.6359 14.6944 12.1384 14.1175V13.8485Z" fill="#00004B" />
      <path d="M23.3429 18.2311C24.6368 18.2311 25.6857 17.2014 25.6857 15.9311C25.6857 14.6608 24.6368 13.6311 23.3429 13.6311C22.0489 13.6311 21 14.6608 21 15.9311C21 17.2014 22.0489 18.2311 23.3429 18.2311Z" fill="#FA7D00" />
    </svg>
  );
}

export default LogoSmall;
