'use client';

import React, { useCallback, useEffect, useState } from 'react';
import { Session } from '@talkjs/react';
// import { UnreadConversation } from 'talkjs/all';
import { ChatParticipantAccess, useLoginChatMutation } from '@generated->anywhere';
import { useAppSelector } from '@providers->Redux/useReduxHooks';
import { UnreadConversation } from 'talkjs/all';
import createContext from '@helpers->client-only/createContext';

type UnreadMessagesData = {
  unreadMessageCount: number;
  guestUnreadMessageCount: number;
  messages: UnreadConversation[],
};

const [Provider, useProvider] = createContext<UnreadMessagesData>('ChatUnreadMessagesContext');

function ChatSession({ children }: ElementChildren) {
  const [rawUnreadMessages, setRawUnreadMessages] = useState<UnreadConversation[]>([]);
  const [chatAuthToken, setChatAuthToken] = useState<string | null>(null);
  const [unreadMessagesData, setUnreadMessagesData] = useState<UnreadMessagesData>({ messages: [], guestUnreadMessageCount: 0, unreadMessageCount: 0 });
  const [loginChatMutation] = useLoginChatMutation();

  const talkJsAppId = process.env.NEXT_PUBLIC_TALK_JS_APP_ID || '';
  const authUserId = useAppSelector((state) => state.account.id);

  const tokenFetcher = useCallback(async () => {
    if (chatAuthToken) {
      return chatAuthToken;
    }
    const { data } = await loginChatMutation();
    const newToken = data?.login_chat.access_token || null;
    setChatAuthToken(newToken);

    return newToken || '';
  }, [chatAuthToken, loginChatMutation]);

  useEffect(() => {
    if (authUserId) {
      setUnreadMessagesData({
        guestUnreadMessageCount: rawUnreadMessages?.reduce((arr, item) => (item.conversation.participants?.[authUserId]?.access === ChatParticipantAccess.Read ? (arr + item.unreadMessageCount) : arr), 0),
        unreadMessageCount: rawUnreadMessages?.reduce((arr, item) => (item.conversation.participants?.[authUserId]?.access === ChatParticipantAccess.ReadWrite ? (arr + item.unreadMessageCount) : arr), 0),
        messages: rawUnreadMessages,
      });
    }
  }, [rawUnreadMessages, authUserId]);

  return (
    <>
      {authUserId ? (
        <Session
          appId={talkJsAppId}
          onUnreadsChange={setRawUnreadMessages}
          tokenFetcher={tokenFetcher}
          userId={authUserId}
        >
          <Provider value={unreadMessagesData}>
            { children }
          </Provider>
        </Session>
      ) : (
        <Provider value={unreadMessagesData}>
          { children }
        </Provider>
      )}

      {/*
        Unread:
        {unreadMessages?.length || 0}
      */}
    </>
  );
}

export {
  useProvider as useChatUnreadMessagesProvider,
};

export default ChatSession;
