import Alert from '@ui->Alert/Alert';

function Error({ heading, message = '', screen }: Props) {
  if (screen) {
    return (
      <div className="h-screen text-center text-red-700 w-full bg-red-50 flex flex-1 flex-col justify-center">
        <h1>An Error occured</h1>
      </div>
    );
  }

  return (
    <Alert title={heading} description={message} state="error" />
  );
}

type Props = Readonly<{
  heading: Nullable<string>
  message?: Nullable<string>
  screen?: boolean
}>;

export type {
  Props as ErrorProps,
};

export default Error;
