import alertStyles, { type AlertStyleProps } from '@ui->Alert/Alert.styles';
import clsx from 'clsx';

function getAlertStyles({ state = 'info', className }: AlertStyleProps) {
  const computed = alertStyles.states[state];

  return {
    title: clsx(alertStyles.common.title, computed.title),
    description: clsx(alertStyles.common.description, computed.description),
    link: clsx(alertStyles.common.link, computed.link),
    icon: clsx(alertStyles.common.icon, computed.icon),
    background: clsx(alertStyles.common.background, computed.background, className),
  };
}

export default getAlertStyles;
