import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import getAlertStyles from '@ui->Alert/getAlertStyles';
import type { AlertStyleProps } from '@ui->Alert/Alert.styles';
import Link from '@ui->Link/Link';

function Alert({
  title: label,
  link: redirect,
  description: moreInfo,
  ...styling
}: Props) {
  const {
    title,
    description,
    background,
    link,
    icon,
  } = getAlertStyles(styling);

  return (
    <div className={background}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className={icon} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className={title}>{label}</h3>
          <div className={description}>
            <p>{moreInfo}</p>
          </div>
          {redirect && (
            <Link
              href={redirect.href}
              className={link}
              label={redirect.label}
            />
          )}
        </div>
      </div>
    </div>
  );
}

type Props = Readonly<AlertStyleProps & {
  title: Nullable<string>;
  description?: Nullable<string>;
  link?: {
    href: string;
    label: string;
  }
}>;

export default Alert;
