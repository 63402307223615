import React from 'react';
import getLinkStyle from '@ui->Link/getLinkStyle';
import LocalizedLink from 'next/link';
import type { LinkStyleProps } from '@ui->Link/Link.styles';

function LinkChildren({
  children, href, as, onClick = null, ...styles
}: Props) {
  const styling = getLinkStyle(styles);

  const additional = onClick ? { onClick: () => onClick() } : {};

  return (
    <LocalizedLink href={href} as={as || href} passHref legacyBehavior>
      <a {...additional} className={styling}>
        {children}
      </a>
    </LocalizedLink>
  );
}

type Props = Readonly<LinkStyleProps & {
  children: React.ReactNode;
  href: string;
  as?: string;
  onClick?: Nullable<() => void>;
}>;

export default React.memo(LinkChildren);
